import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "#F2F4F7",
    overflow: "hidden",
  },
  headerSection: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  contentSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: "70px", // Adjust this based on your header height
    paddingBottom: "70px", // Adjust this based on your footer height
  },
  contentStyle: {
    padding: "20px",
    width: "100%",
    overflowY: "auto",
    flex: 1,
  },
  footerSection: {
    position: "fixed",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: 1000,
  },
}));

export default function MainLayout({ children }) {
  const classes = useStyles();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerSection}>
        <Header />
      </Box>
      <Box className={classes.contentSection}>
        <Box className={classes.contentStyle}>{children}</Box>
      </Box>
      <Box className={classes.footerSection}>
        <Footer />
      </Box>
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
