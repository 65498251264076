import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "15px 0px",
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    background: "#fff",
    boxShadow: '0 0 10px 0 #D9D9D9',
    color: "#fff",
    textAlign: "center",
    height: "19px", // Adjust this height as needed
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerSection}>
      <Typography variant="h5">
        © 2024 Applicant T LLC All rights reserved
      </Typography>
    </Box>
  );
};

export default Footer;
