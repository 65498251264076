import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Grid,
  IconButton,
  Hidden,
  SvgIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  headerbox: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "10px 2%",
    boxShadow: "0 0 10px 0 #D9D9D9",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  logos: {
    marginRight: "auto",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

const TopBar = ({ onMobileNavOpen }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box className={classes.headerbox}>
      <Grid container alignItems="center" spacing={2}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon style={{ color: "#262626", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Grid item className={classes.logos}>
          <img src="/images/Logo.svg" alt="Logo" />
        </Grid>
        <Grid item className={classes.icons}>
          <IconButton style={{ marginLeft: "20px" }}>
            <img src="/images/Icon.svg" alt="Overview Icon" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBar;
