import React, { Suspense, Fragment } from "react";
import { Router, Switch, Route ,Redirect} from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "./routes";
import { createBrowserHistory } from "history";
import AuthContext from "./context/Auth";
//import PageLoading from "src/component/PageLoading";
import PageLoading from "./component/PageLoading";
import AuthGuard from "./config/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import {Grid} from "@material-ui/core"
import { Grid4x4Rounded } from "@mui/icons-material";

function App() {
  const theme = createMuiTheme();
  const history = createBrowserHistory();

  console.log(theme);
  return (
    <Grid >
      <ThemeProvider theme={theme}>     
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Router history={history}>                          
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Grid>
  );
}

export default App;

function RenderRoutes({ data }) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Toaster position="top-right" />
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
        {/* Add a fallback route here */}
        {/* <Route path="*">
          <Redirect to="/login" />
        </Route> */}
      </Switch>
    </Suspense>
  );
}

