import React, { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "../context/Auth";

export default function AuthGuard({ children }) {
  const location = useLocation();
  const auth = useContext(AuthContext);

  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
// import React, { useContext } from "react";
// import { Redirect } from "react-router-dom";
// import { AuthContext } from "src/context/Auth";

// export default function AuthGuard(props) {
//   const { children } = props;
//   const auth = useContext(AuthContext);
//   if (!auth.userLoggedIn) {
//     return <Redirect to="/" />;
//   }

//   return <>{children}</>;
// }
