import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import ApiConfig from "../config/apiconfig";
import { useHistory } from "react-router-dom";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isLoading, setIsLoading] = useState("");
  const [userData, setUserData] = useState({});
  const history = useHistory();
  useEffect(() => {
    console.log("userData: ", userData);
  }, [userData]);

  const getAdminProfile = async () => {
    setIsLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getAdminProfile,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          userId: sessionStorage.getItem("Id"),
        },
      });
      if (res?.data?.responseCode === 200) {
        setUserData(res?.data?.result);
      }
    } catch (error) {
      if (
        error?.response?.data?.responseCode === 500 ||
        error?.response?.data?.responseMessage === "jwt malformed"
      ) {
        toast.error("Session Expired!");
        sessionStorage.removeItem("token");
        setIsLogin(false);
        history.push("/");
        // window.location.href = "/";
      }
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getAdminProfile();
    }
  }, [sessionStorage.getItem("token")]);
  let data = {
    userLoggedIn: isLogin,
    setIsLogin,
    userData,
    getAdminProfile: getAdminProfile,
    setUserData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
